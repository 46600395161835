@import "./../../common/styles";
@import "swiper";
@import "video-js";

body {
  background-color: #000;
  min-width: 1200px;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", "microsoft yahei", sans-serif;
  overflow: hidden;
  overflow-y: auto;
}

body .body-wrapper {
  opacity: 0;
  transition: opacity 150ms ease-in;
}
body.ready .body-wrapper {
  opacity: 1;
}

.hide {
  display: none;
}

a {
  text-decoration: none;
  i {
    text-decoration: none;
    color: #e09e01;
  }
}

p {
  margin: 0;
  padding: 0;
}

table,
th {
  //border: none;
  padding: 10px;
}

table,
td {
  //border: none;
  padding: 10px;
  background-color: none;
}

.aff-table tr:has(td:empty) {
  display: none;
}

.main-wrapper {
  width: 1000px;
  max-width: 1048px;
  margin: 0 auto;
  position: relative;
  overflow: visible;
  &.header {
    width: 1100px;
    max-width: 1100px;
  }
}

/*Top Header Starts Here*/

.top-header {
  height: 48px;
  background-color: #bc3638;
  width: 100%;
  > .main-wrapper {
    display: flex;
    align-items: center;
  }
  .login-wrapper {
    margin-left: auto;
  }
}

.logo {
  width: 237px;
  height: 60px;
  display: block;
  position: absolute;
  top: 5px;
  > img {
    height: 100%;
    display: block;
  }
}

.top-content {
  .time {
    margin-left: 4px;
    color: #fff;
    height: 48px;
    line-height: 48px;
    font-size: 12px;
    display: inline-block;
  }
}

.datetime {
  float: left;
  height: 48px;
  line-height: 48px;
  font-size: 12px;
  color: #fff;
  display: inline-block;
}

.quick-links {
  float: left;
  height: 48px;
  line-height: 48px;
  font-size: 9.5pt;
  display: inline-block;
  a:not(:last-child):after {
    content: "|";
    position: absolute;
    color: #fff;
    line-height: 34pt;
    right: -15px;
  }
}

.qlinks {
  color: #fff;
  display: inline-block;
  padding-left: 20px;
  position: relative;
}

.login-wrapper {
  float: right;
  height: 48px;
  line-height: 48px;
  font-size: 10.5pt;
  color: #fff;
  &.after-login {
    .username {
      color: #ffd500;
      margin: 0 4px;
      display: inline-block;
    }
    a {
      text-decoration: underline;
      color: #fff;
      &:not(:last-child) {
        margin-right: 6px;
      }
    }
  }
}

.button {
  display: inline-block;
  border: 0;
  outline: none;
  background: #ff9700;
  background: linear-gradient(to bottom, #ffd500, #ff9700);
  width: 65px;
  height: 30px;
  border-radius: 20px;
  text-align: center;
  line-height: 30px;
  /*border: 1px solid rgba(255, 255, 255, 0.1);*/
  transition-duration: 400ms;
  transition-timing-function: ease;
  box-sizing: border-box;
  text-decoration: none;
  color: #fff;
  &:hover {
    box-shadow: 0 0 2px #fff0ab, 0 0 10px rgba(255, 240, 171, 0.5);
  }
  &:not(:last-child) {
    margin-right: 4px;
  }
}

.input {
  &.verify,
  &.username,
  &.password {
    display: inline-block;
    width: 160px;
    height: 30px;
    line-height: 30px;
    //background-color: transparent;
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.6);
    position: relative;
    margin-right: 4px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px rgba(255, 247, 146, 0.42) inset;
  transition: background-color 5000s ease-in-out 0s;
  border-radius: 20px;
  box-sizing: border-box;
}

.input.verify {
  position: relative;
  input {
    padding-left: 15px;
  }
  img {
    position: absolute;
    height: 26px;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    border-radius: 3px;
  }
}

.login-wrapper input {
  background-color: transparent;
  border: 0;
  outline: none;
  font-size: 14px;
  padding-left: 35px;
  color: #fff;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  &::placeholder {
    color: rgba(255, 255, 255, 0.8);
  }
}

.input {
  &.username {
    display: inline-block;
    color: #fff;
    &:before {
      content: "";
      background-image: url(../img/ico_username.png);
      background-repeat: no-repeat;
      height: 17px;
      width: 16px;
      position: absolute;
      top: 7px;
      left: 12px;
    }
  }
  &.password {
    &:before {
      content: "";
      background-image: url(../img/ico_password.png);
      background-repeat: no-repeat;
      height: 17px;
      width: 14px;
      position: absolute;
      top: 7px;
      left: 12px;
    }
    display: inline-block;
  }
}

.btn-pass {
  color: #ff8389;
  background-color: #fff;
  border-radius: 20px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  width: 50px;
  font-size: 9pt;
  position: absolute;
  right: 5px;
  top: 4px;
  &:hover {
    background-color: #ce3333;
  }
}

/*Main Menu Starts Here*/

.navigation {
  height: 70px;
  width: 100%;
  background-color: #ffd5d0;
  background-image: url(../img/navigation_bg.jpg);
  background-repeat: repeat-x;
}

.main-menu {
  float: right;
  height: 70px;
  /*padding: 24px 0 24px 25px;*/
}

.menu-links {
  &:hover {
    border-bottom: 2px solid #ff9327;
    .lottery-dropdown-wrapper {
      display: block;
    }
    color: #fff;
    &:before {
      opacity: 1;
      transform: translateX(0);
    }
    &:after {
      opacity: 1;
      transform: translateX(1px) rotate(4deg) scaleX(0.5);
    }
  }
  &.active {
    color: #fff;
    border-bottom: 2px solid #ff9327;
    &:before {
      opacity: 1;
      transform: translateX(0);
    }
    &:after {
      opacity: 1;
      transform: translateX(1px) rotate(4deg) scaleX(0.5);
    }
  }
  &.hide {
    display: none;
  }
  color: #000;
  display: inline-block;
  width: 100px;
  height: 70px;
  position: relative;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  box-sizing: border-box;
  &:before {
    content: "";
    background: linear-gradient(to bottom, #ff9700, #ffd500);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    transition: all 200ms ease-in-out;
    transform: translateX(-5%);
  }
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    opacity: 0;
    transition: all 200ms ease-in-out;
    transform: translateX(0) rotate(0) scaleX(0.5);
    transform-origin: bottom right;
    background: linear-gradient(to bottom, transparent, #efa930);
  }
  .ch {
    font-size: 10.5pt;
    line-height: 10.5pt;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    z-index: 2;
  }
  .en {
    font-size: 7.5pt;
    text-align: center;
    line-height: 7.5pt;
    padding-top: 5px;
    position: absolute;
    left: 0;
    right: 0;
    top: 34px;
    z-index: 2;
    white-space: nowrap;
  }
}

/*Navigation Lottery Dropdown Starts Here*/

.lottery-dropdown-wrapper {
  position: absolute;
  top: 70px;
  left: 100%;
  transform: translateX(-50%);
  display: none;
  z-index: 10;
  &.ydl,
  &.wdl {
    left: initial;
    right: 0;
    transform: translateX(10%);
  }
}

.menu-links.lottery:hover .lottery-dropdown-wrapper {
  /*display: block;*/
}

.lottery-dropdown {
  width: 1000px;
  background-color: rgba(0, 0, 0, 0.6);
  position: relative;
  z-index: 10;
  top: 0;
  border-radius: 10px;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 2px #fff0ab, 0 0 10px rgba(255, 240, 171, 0.5);
}

.wdl-lottery-dropdown {
  width: 650px;
  background-color: rgba(0, 0, 0, 0.6);
  position: relative;
  z-index: 10;
  top: 0;
  border-radius: 10px;
  left: 0;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 2px #fff0ab, 0 0 10px rgba(255, 240, 171, 0.5);
}

.menu-links.lottery:after {
  content: "";
  width: 12px;
  height: 12px;
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
  box-sizing: border-box;
  transform: rotate(45deg);
  box-shadow: -1px -1px 1px #fff0ab, -1px -1px 2px rgba(255, 240, 171, 0);
}

.lottery-dropdown-title {
  color: #fff;
  font-size: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  padding-bottom: 10px;
  position: relative;
  padding-left: 16px;
  &:before {
    content: "";
    position: absolute;
    top: 9px;
    left: 0;
    border-radius: 50%;
    width: 4px;
    height: 4px;
    background-color: #fff;
  }
}

.lottery_guan {
  background: url(../img/icon_guan.png) center / contain no-repeat;
  width: 24px;
  height: 24px;
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 0 7px;
}

.lottery-dropdown-wrapper ul {
  li {
    list-style: none;
    color: rgba(255, 255, 255, 0.8);
    display: inline-block;
    margin: 0 20px 25px 0;
    font-size: 10pt;
    text-align: center;
    &:nth-child(8n) {
      margin-right: 0;
    }
  }
  padding: 0;
}

.lottery-dropdown ul li {
  width: 97px;
  height: 97px;
  border-radius: 20px;
  background-color: #b62929;
  position: relative;
  cursor: pointer;
  .lottery-games {
    background-image: url(../img/lottery_dropdown.png);
    width: 97px;
    height: 97px;
  }
  @for $i from 1 through 25 {
    &:nth-child(#{$i}) .lottery-games {
      background-position-x: (($i - 1) % 8 * -113.7 - 16) * 1px;
      background-position-y: (floor(($i - 1) /8) * -100 - 10) * 1px;
    }
  }
}

.wdl-lottery-dropdown ul {
  color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-wrap: wrap;
  font-size: 10pt;
  text-align: center;
  li {
    width: 100px;
    height: 100px;
    flex-basis: calc(100% / 4);
    position: relative;
    margin: 20px 0;
    cursor: pointer;
    .lottery-games {
      width: 100px;
      height: 100px;
      margin: 0 auto 5px;
    }
    &.game-1 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -10px -10px;
    }
    &.game-2 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -130px -10px;
    }
    &.game-3 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -10px -130px;
    }
    &.game-4 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -130px -130px;
    }
    &.game-5 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -250px -10px;
    }
    &.game-6 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -250px -130px;
    }
    &.game-7 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -10px -250px;
    }
    &.game-8 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -130px -250px;
    }
    &.game-9 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -250px -250px;
    }
    &.game-10 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -370px -10px;
    }
    &.game-11 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -370px -130px;
    }
    &.game-12 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -370px -250px;
    }
    &.game-13 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -10px -370px;
    }
    &.game-14 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -130px -370px;
    }
    &.game-15 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -250px -370px;
    }
    &.game-16 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -370px -370px;
    }
    &.game-17 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -490px -10px;
    }
    &.game-18 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -490px -130px;
    }
    &.game-19 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -490px -250px;
    }
    &.game-20 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -490px -370px;
    }
    &.game-21 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -10px -490px;
    }
    &.game-22 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -130px -490px;
    }
    &.game-23 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -250px -490px;
    }
    &.game-24 .lottery-games {
      background: url("../img/wdl_lottery_dropdown.png") -370px -490px;
    }
  }
}

/*Main Banner Starts Here*/

.main-banner {
  height: 490px;
  width: 100%;
  overflow: hidden;
  position: relative;
  .swiper-container {
    width: 100%;
    height: 490px;
    color: #fff;
    /*min-width: 1048px;*/
    .swiper-pagination.banner {
      bottom: 50px;
      .swiper-pagination-bullet {
        &.swiper-pagination-bullet-active {
          background-color: #fff;
        }
        background-color: #fec52e;
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
  }
}

.swiper-slide {
  overflow: hidden;
  .img-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    filter: blur(10px) grayscale(50%);
    z-index: 1;
  }
  img {
    width: auto;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2;
  }
}

/*News Section Starts Here*/

.news {
  background-color: rgba(183, 41, 40, 0.6);
  height: 40px;
  font-size: 10.5pt;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  > .main-wrapper {
    display: flex;
    align-items: center;
    width: 1048px;
    .news-wrapper {
      width: 50%;
      cursor: pointer;
      &.wdl-news-wrapper {
        width: calc(100% - 23px);
        color: white;
      }
      .news-content {
        width: calc(100% - 40px);
      }
    }
    .trans-wrapper {
      width: 50%;
      white-space: nowrap;
      height: 40px;
      line-height: 40px;
      .trans-content {
        display: inline-block;
        vertical-align: middle;
        width: calc(100% - 40px);
        color: #fff;
        position: relative;
        height: 40px;
        overflow: hidden;
      }
    }
  }

  .info-wrapper {
    .info {
      position: absolute;
      top: 0;
      left: 0;
      animation: infoHide 300ms ease;
      animation-fill-mode: both;
      &.active {
        animation: infoShow 300ms ease;
      }
    }
    @keyframes infoShow {
      from {
        transform: translateY(100%);
      }
      to {
        transform: translateY(0);
      }
    }

    @keyframes infoHide {
      from {
        transform: translateY(0%);
      }
      to {
        transform: translateY(-100%);
      }
    }
  }
}

// Notice modal
#noticeWrapper {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  color: rgba(0, 0, 0, 0.87);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  animation: fade-in 200ms ease-in-out;
  animation-fill-mode: forwards;
  .notice-content {
    position: relative;
    background: white;
    border-radius: 0.25rem;
    min-width: 800px;
    .close {
      position: absolute;
      width: 40px;
      height: 40px;
      right: 0;
      top: 0;
      cursor: pointer;
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 50%;
        height: 0;
        width: 60%;
        left: 20%;
        border: 0.5px solid black;
        transition: transform 200ms ease-in-out;
      }
      &:before {
        transform: rotate(-45deg);
      }
      &:after {
        transform: rotate(45deg);
      }
      &:hover {
        &:before {
          transform: rotate(135deg);
        }
        &:after {
          transform: rotate(225deg);
          transition-delay: 50ms;
        }
      }
    }
    h3 {
      margin: 20px 20px 5px 20px;
      color: #4280d0;
    }

    .table-wrapper {
      max-height: 600px;
      min-height: 250px;
      overflow: auto;
      padding: 20px;
    }
    th,
    td {
      border: 1px solid #ccc;
      padding: 5px;
    }
    table {
      width: 800px;
      border-collapse: collapse;
      tr > *:first-child {
        width: 150px;
      }
      th {
        color: #4280d0;
      }
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.news-wrapper {
  white-space: nowrap;
  height: 40px;
  line-height: 40px;
}

.news-title {
  background: url(../img/news.png) no-repeat;
  width: 23px;
  height: 20px;
  display: inline-block;
  vertical-align: middle;
}

.news-content {
  display: inline-block;
  vertical-align: middle;
  width: 600px;
}

.swiper-container.news-content {
  height: 40px;
  //width: 900px;
  min-width: initial;
}

.news-content p {
  line-height: 40px;
  font-size: 10.5pt;
  margin: 0;
  padding: 0 30px 0 10px;
  color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*Hot Lottery Section Starts Here*/

.main-wrapper.hotlottery {
  width: 940px;
  max-width: initial;
  padding-top: 20px;
  &.guest {
    display: none;
    & + .hot-lottery {
      display: none;
    }
  }
}

.hot-lottery {
  background-color: #c03939;
  height: 560px;
  background: url(../img/hotlottery_bg.jpg) no-repeat;
  background-position: center;
}

.subtitle {
  height: 122px;
  position: relative;
  text-align: center;
  background-color: #ffd5d0;
  background: url(../img/hl_header_bg.png) center no-repeat;
  label {
    &:before {
      content: "";
      background: url(../img/hotlottery_arrow.png) center / 100% 100% no-repeat;
      width: 331px;
      height: 41px;
      position: absolute;
      display: block;
      top: 27px;
      right: 65%;
    }
    &:after {
      content: "";
      background: url(../img/hotlottery_arrow.png) center / 100% 100% no-repeat;
      width: 331px;
      height: 41px;
      position: absolute;
      display: block;
      top: 27px;
      left: 65%;
      -webkit-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
  .ch {
    font-size: 18pt;
    color: #b62929;
    padding-top: 20px;
  }
  .en {
    font-size: 10pt;
    color: #e45353;
    line-height: 24px;
  }
}

.hl-box-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  .hl-box {
    position: relative;
    display: flex;
    justify-content: center;
    width: 270px;
    height: 350px;
    margin: 0 auto;
    &:hover {
      &:after {
        transform: rotate(0);
      }
    }

    &.wdl {
      &:nth-child(2):before {
        background-image: url(../img/hotlottery2_wdl.png);
      }
      &:last-child:before {
        background-image: url(../img/hotlottery3_wdl.png);
      }
    }

    &:before {
      content: "";
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 1;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }
    &:after {
      content: "";
      background-color: #f25558;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      z-index: 0;
      transform: rotate(10deg);
      transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    &:first-child:before {
      background-image: url(../img/hotlottery1.png);
    }
    &:nth-child(2):before {
      background-image: url(../img/hotlottery2.png);
    }
    &:last-child:before {
      background-image: url(../img/hotlottery3.png);
    }
  }
}

.hl-link {
  position: absolute;
  text-align: center;
  color: #fff;
  margin: auto;
  bottom: 20px;
  font-size: 16pt;
  z-index: 2;
  height: 70px;
  line-height: 70px;
  width: calc(100% + 20px);
  background: linear-gradient(to bottom, #ff7d38, #e82d24);
  left: -10px;
  &:before {
    content: "";
    position: absolute;
    border-top: 5px solid transparent;
    border-right: 7px solid #941218;
    border-bottom: 3px solid #941218;
    border-left: 5px solid transparent;
    //background-color: #941218;
    top: -8px;
    left: 0;
  }
  &:after {
    content: "";
    position: absolute;
    border-top: 5px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 3px solid #941218;
    border-left: 5px solid #941218;
    //background-color: #941218;
    top: -8px;
    right: 0;
  }
}

/*Bottom 3 Section Starts Here*/

.service-list {
  background: url(../img/service_bg.jpg) no-repeat;
  background-position: center;
  background-color: #f9ebeb;
}

.servicelist-wrapper {
  display: inline-block;
  padding: 35px 0;
  height: 318px;
  box-sizing: border-box;
  width: 100%;
}

.slist-box {
  width: calc((99% - 100px) / 3);
  text-align: left;
  display: inline-block;
  vertical-align: middle;
  position: relative;
  height: 100px;
  &:not(:last-child) {
    content: "";
    margin-right: 50px;
  }
}

.sl-title {
  top: 0;
  display: flex;
  height: 36px;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  align-items: center;
  .ch {
    font-size: 21px;
    color: #eb2523;
    display: inline-block;
    font-weight: 600;
    border-bottom: 3px solid #eb2523;
    height: 34px;
    vertical-align: middle;
    position: relative;
    &:before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      bottom: 0;
      border-top: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #eb2523;
      border-left: 5px solid transparent;
      width: 0;
    }
    > img {
      display: inline-block;
      vertical-align: middle;
      margin-right: 8px;
      margin-bottom: 3px;
    }
  }
  .en {
    font-size: 8pt;
    color: #f47170;
    display: inline-block;
    margin-left: auto;
  }
}

/*Service Advantages Section Starts Here*/

.sl1-title {
  top: 0;
  display: block;
  height: 40px;
  margin-bottom: 10px;
}

.sl1-deposit,
.sl1-withdraw {
  color: #717070;
  font-size: 10.5pt;
  width: 100%;
  display: block;
  margin-top: 30px;
  height: 80px;
}

.deposit-left {
  float: left;
  display: inline-block;
}

.deposit-right {
  float: right;
  display: inline-block;
  height: 55px;
}

.dp-wrapper:after {
  content: "";
  clear: both;
  display: table;
}

.dp-title,
.wd-title {
  font-size: 11pt;
  margin: 2px 0;
  color: #827a7a;
}

.dp-time,
.wd-time {
  font-size: 9pt;
  color: #827a7a;
}

.dp-no,
.dp-sec {
  display: inline-block;
}

.dp-no {
  font-size: 24pt;
  color: #827a7a;
  line-height: 40pt;
}

.dp-bargrey {
  width: 100%;
  height: 4px;
  border-radius: 20px;
  background-color: #d0bdbd;
  position: relative;
  //overflow: hidden;
}

.dp-barred {
  width: 0;
  height: 4px;
  border-radius: 20px;
  background: linear-gradient(to right, #ffb224, #fd4249);
  position: absolute;
  left: 0;
  top: 0;
  transition: width 2s ease-in-out;
  box-shadow: 0px 2px 8px rgba(30, 2, 39, 0.4);
  &.active {
    width: 50%;
  }
  &:before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 2px solid #fd4249;
    background-color: #fff;
    right: 0;
    top: -3px;
  }
}

.withdraw-left {
  float: left;
  display: inline-block;
}

.withdraw-right {
  float: right;
  display: inline-block;
  height: 55px;
}

.wd-wrapper:after {
  content: "";
  clear: both;
  display: table;
}

.wd-no,
.wd-sec {
  display: inline-block;
}

.wd-no {
  font-size: 24pt;
  color: #827a7a;
  line-height: 40pt;
}

.wd-bargrey {
  width: 100%;
  height: 4px;
  border-radius: 20px;
  background-color: #d0bdbd;
  position: relative;
  //overflow: hidden;
}

.wd-barred {
  width: 0;
  height: 4px;
  border-radius: 20px;
  background: linear-gradient(to right, #ffb224, #fd4249);
  position: absolute;
  left: 0;
  top: 0;
  transition: width 2s ease-in-out;
  box-shadow: 0px 2px 8px rgba(30, 2, 39, 0.4);
  &.active {
    width: 70%;
  }
  &:before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 2px solid #fd4249;
    background-color: #fff;
    right: 0;
    top: -3px;
  }
}

/*Mobile Terminal Downloads Starts Here*/

.sl2-title {
  padding-bottom: 10px;
  color: #716f6f;
}

.sl2-text {
  font-size: 10.5pt;
  color: #717070;
  line-height: 17pt;
  margin-top: 30px;
}

.sl2-img {
  background: url(../img/terminal_download.png) no-repeat;
  width: 245px;
  height: 70px;
  margin-top: 50px;
  position: relative;
}

.appdl {
  width: 245px;
  height: 70px;
  position: absolute;
}

/*Help Center Section Starts Here*/

.sl3-text-wrapper {
  display: block;
  font-size: 10pt;
  color: #717070;
  height: 100px;
  line-height: 16pt;
  margin-top: 30px;
}

.sl3-left {
  float: left;
  display: inline-block;
  width: 100px;
  padding-right: 30px;
  a {
    display: block;
    padding-bottom: 20px;
    color: #717070;
  }
}

.sl3-star {
  background: url(../img/star.png) no-repeat;
  height: 11px;
  width: 11px;
  display: inline-block;
  padding-right: 12px;
}

/*Homepage Footer Starts Here*/

.foot {
  background-color: #191919;
  text-align: center;
  height: auto;
  background-image: url(../img/footer_bg.jpg);
  background-repeat: repeat-x;
}

.footer {
  background-color: #000;
  height: 61px;
}

.foot-text1 {
  background: url(../img/providers.jpg) no-repeat;
  padding: 0;
  height: 100px;
  background-position: center;
  display: block;
}

.foot-text2 {
  color: #fff;
  font-size: 9.5pt;
  padding: 0;
  height: 75px;
  line-height: 66px;
  display: block;
}

.ft-support {
  display: inline-block;
  font-size: 12pt;
}

.ft-browser {
  display: inline-block;
  font-size: 12pt;
}

.foot-text3 {
  color: #fff;
  font-size: 10pt;
  padding: 0;
  height: 50px;
  line-height: 46px;
  display: block;
}

.footer-text {
  background: url(../img/footer.jpg) no-repeat;
  padding: 0;
  height: 61px;
  background-position: center;
  display: block;
}

.ft-divider {
  background: url(../img/footer_divider.png) no-repeat;
  padding: 0;
  height: 1px;
  background-position: center;
  clear: both;
}

.ft-sp-img {
  background: url(../img/support.png) no-repeat;
  width: 102px;
  height: 29px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

.ft-bs-img {
  background: url(../img/browser.png) no-repeat;
  width: 300px;
  height: 28px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 20px;
}

/*Subpage Layout Starts Here*/

.subpage-body {
  background-color: #fff;
  height: auto;
  padding-bottom: 30px;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  p {
    color: #333;
  }
}

.sub-header {
  height: 140px;
  padding: 47px 0;
  box-sizing: border-box;
}

.sub-title,
.sub-desc {
  display: block;
}

.sub-content {
  height: auto;
  position: relative;
  overflow: auto;
  box-sizing: border-box;
}

.sub-content-wrapper {
  background-color: #fff;
  height: 360px;
  width: 95%;
  margin: auto;
  box-sizing: border-box;
}

/*Register Starts Here*/

.subpage-body.registration {
  /*min-height: calc(100vh - 48px - 71px - 227px - 61px);*/
  background: url(../img/registration_bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  box-sizing: border-box;
  height: auto;
}

.sub-content.registration {
  background-color: #faeeee;
}

.sub-header.registration {
  height: 125px;
  width: 800px;
  margin: auto;
  padding: 0;
  background-image: url(../img/ribbon_banner.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  .sub-title {
    color: #bc3638;
    font-weight: bold;
    text-align: center;
    width: 70%;
    height: 32px;
    font-size: 30px;
    position: absolute;
    top: 40px;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.reg-login-btn {
  display: inline-block;
  background: url(../img/button_reg.png) no-repeat;
  height: 113px;
  width: 209px;
  margin-top: 13px;
  vertical-align: middle;
  position: relative;
  a {
    position: absolute;
    width: 97%;
    height: 95%;
    top: 6px;
  }
}

.reg-banner {
  display: inline-block;
  background: url(../img/banner_reg.png) no-repeat;
  height: 125px;
  width: 786px;
  margin-top: 1px;
  vertical-align: middle;
}

.title-reg {
  font-size: 44pt;
  color: #a2a2a2;
  font-weight: 300;
  vertical-align: top;
  letter-spacing: 3px;
  display: inline-block;
}

.reg-steps {
  left: 0;
  right: 0;
  margin: auto;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.reg-steps-box {
  &.red {
    background-color: #ff7337;
    border-left: 6px solid #ff7337;
    &:not(:first-child) {
      border-left: 6px solid #fff;
    }
    &:after {
      border-left: 20px solid #ff7337;
    }
  }
  background-color: #efe8e8;
  display: inline-block;
  vertical-align: middle;
  width: calc(100% / 3);
  text-align: center;
  line-height: 40px;
  height: 40px;
  float: left;
  box-sizing: border-box;
  position: relative;
  border-left: 6px solid #fff;
  box-sizing: border-box;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    border-top: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #fff;
  }
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -40px;
    border-top: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 20px solid #efe8e8;
    z-index: 1;
  }
  &:first-child {
    &:before {
      display: none;
    }
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
}

.steps-no {
  display: inline-block;
  vertical-align: middle;
  font-size: 18pt;
  color: #978e8e;
}

.steps-title {
  display: inline-block;
  vertical-align: middle;
  font-size: 10.5pt;
  color: #978e8e;
}

.steps-no.red,
.steps-title.red {
  color: #fff;
}

.reg-form {
  height: auto;
  padding: 40px 20px 0 20px;
}

.rform {
  position: relative;
  &.row {
    height: 50px;
    line-height: 50px;
    padding-bottom: 5px;
  }
  &.col1 {
    width: 150px;
    display: inline-block;
    vertical-align: middle;
    text-align: right;
    font-size: 9pt;
    color: #eb2523;
  }
  &.col2 {
    width: 280px;
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
    margin-right: 20px;
    input {
      width: 100%;
      height: 30px;
      line-height: 30px;
      background-color: transparent;
      -webkit-appearance: none;
      border-radius: 5px;
      outline: none;
      border: 1px solid #978e8e;
    }
    .verify-code {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
    }
  }
  &.col3 {
    display: inline-block;
    vertical-align: middle;
    font-size: 9pt;
  }
  &.row {
    .button {
      width: 115px;
      left: 0;
      right: 0;
      margin: auto;
      height: 30px;
      font-size: 10pt;
      display: block;
      margin-top: 20px;
      border-radius: 4px;
    }
    div a {
      color: #fff;
    }
  }
}

/*Promotion Starts Here*/

.subpage-body.promo {
  min-height: calc(100vh - 48px - 71px - 490px - 227px - 61px);
  background: url(../img/promo_bg.jpg);
  background-position: center top;
  background-size: 100% auto;
  box-sizing: border-box;
  height: auto;
}

.sub-header.promo {
  height: 56px;
  width: 100%;
  background-color: #ffd5d0;
  background-image: url(../img/navigation_bg.jpg);
  background-repeat: repeat-x;
  padding: 0;
}

.sub-content.promo {
  background-color: rgba(0, 0, 0, 0.7);
  height: auto;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.subpage-body.aff-index {
  height: 1330px;
  background-image: url(../img/aff-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  a {
    color: white;

    &.color-link {
      color: #db1416;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  .text-wrapper {
    text-align: center;
    .image-title {
      width: 100%;
      padding-top: 40px;
      margin-bottom: 20px;
      text-align: center;
    }
    .title {
      color: transparent;
      background-image: linear-gradient(to bottom, #f46e45, #db1416);
      -webkit-background-clip: text;
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 4px;
    }
    .sub-title {
      font-size: 14px;

      color: #db1416;
      margin-bottom: 20px;
    }
    .paragraph-wrapper {
      margin-bottom: 20px;
      p {
        display: inline-block;
        line-height: 28px;
        color: #862528;
        font-size: 14px;
        border-bottom: 1px dotted #862528;
      }
    }
    .ribbon {
      display: inline-block;
      color: #e5101c;
      border-top: 1px solid #e5101c;
      border-bottom: 1px solid #e5101c;
      position: relative;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      padding: 0 30px;
      box-sizing: border-box;
      &:before {
        content: "";
        width: 23px;
        height: 23px;
        position: absolute;
        border-right: 1px solid #e5101c;
        border-top: 1px solid #e5101c;
        left: 0;
        top: -1px;
        transform: rotate(45deg);
        transform-origin: top left;
        box-sizing: border-box;
      }
      &:after {
        content: "";
        width: 23px;
        height: 23px;
        position: absolute;
        border-left: 1px solid #e5101c;
        border-bottom: 1px solid #e5101c;
        right: 0;
        bottom: -1px;
        transform: rotate(45deg);
        transform-origin: bottom right;
        box-sizing: border-box;
      }
    }
  }
  .aff-info {
    position: absolute;
    top: 960px;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    .title {
      display: block;
      margin: 0 auto 20px;
    }
    .table-wrapper {
      display: flex;
    }
    table {
      border: 1px solid #fff;
      padding: 0;
      border-collapse: collapse;
      color: #fff;
      text-align: center;
      flex-grow: 1;
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid #fff;
        }
        td {
          &:not(:last-child) {
            border-right: 1px solid #fff;
          }

          &:last-child {
            border-right: 1px solid #fff;
          }
        }
      }
      .icon {
        height: 14px;
        margin-right: 4px;
        &.large {
          height: 20px;
        }
      }
    }
    .contact-qr img {
      width: 160px;
      height: 160px;
      background: white;
    }
  }
}

.subpage-body.aff-deal {
  background: #fe7409;
  background-image: url("../img/aff2_bg.jpg");
  background-position: center top;
  background-size: 100% auto;
  background-repeat: no-repeat;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", "microsoft yahei", sans-serif;
  .text-wrapper {
    margin-bottom: 30px;
  }
  .image-title {
    padding-top: 60px;
    width: 100%;
    text-align: center;
  }
  .title {
    width: 100%;
    text-align: center;
    h3 {
      display: inline-block;
      height: 50px;
      line-height: 50px;
      padding: 0 40px;
      box-shadow: inset 0 2px 0 rgba(225, 38, 17, 0.9);
      border-radius: 80px;
      text-align: center;
      background-image: linear-gradient(-45deg, #f25430, #f16645, #f25430, #f16645, #f25430);
      background-size: 4px 4px;
      font-weight: 300;
      margin-top: 50px;
    }
  }
  p {
    color: white;
    font-weight: 300;
    font-size: 14px;
  }
}

.section-promo {
  padding: 20px;
  box-sizing: border-box;
  .promo-wrapper {
    .item {
      margin: 30px auto;
      overflow: hidden;
      .item-head {
        border-radius: 25px;
        position: relative;
        z-index: 2;
        margin: 0;
        cursor: pointer;
      }
      .item-head:not(.image) {
        min-height: 150px;
        height: auto;
        border-radius: 150px;
        display: table;
        width: 100%;
        background: linear-gradient(to bottom, #eb4d52, #d02a34);
        border: 2px solid #fffe9d;
        box-sizing: border-box;
        position: relative;
        .text {
          color: #fff95b;
          display: table-cell;
          vertical-align: middle;
          text-align: center;
          h1 {
            font-weight: bold;
          }
        }
      }
      .item-content {
        display: none;
        position: relative;
        // bottom: 90px;
        // margin-bottom: -90px;
        margin-top: 10px;
        background-color: #bc3638;
        border-radius: 25px;
        padding: 20px;
        z-index: 0;
      }
    }
    img {
      display: block;
      width: 100%;
    }
  }
}

.promo-menu-links {
  &:hover div {
    color: #fff;
  }
  &.active div {
    color: #fff;
    pointer-events: none;
  }
  color: #000;
  display: inline-block;
  width: 100px;
  height: 56px;
  position: relative;
  cursor: pointer;
  &:before {
    content: "";
    background-color: #ce3333;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 10px;
    z-index: 1;
    opacity: 0;
    transform: skew(-20deg) translateX(-10%);
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
  }
  &.active:before,
  &:hover:before {
    opacity: 1;
    transform: skew(-20deg) translateX(0);
  }
}

.promo-links {
  color: #b62929;
  font-size: 12.5pt;
  text-align: center;
  position: absolute;
  left: 18px;
  right: 0;
  line-height: 56px;
  z-index: 2;
}

.promo-menu-links div {
  &.active {
    pointer-events: none;
    color: #fff;
  }
  &:hover {
    color: #fff;
  }
}

.promo-title {
  font-size: 24pt;
}

.promo-box {
  display: block;
  width: 100%;
  border: 1px solid #999999;
  box-sizing: border-box;
  height: 150px;
  margin-bottom: 10px;
}

/*FAQ Starts Here*/

.sub-title.faq {
  background: url(../img/faq_title.png) no-repeat;
  width: 98px;
  height: 25px;
}

.sub-desc.faq {
  background: url(../img/faq_desc.png) no-repeat;
  width: 404px;
  height: 14px;
  margin-top: 7px;
}

.subpage-body.faq {
  min-height: calc(100vh - 48px - 71px - 227px - 61px);
  background: url(../img/faq_bg.jpg);
  background-repeat: repeat-y;
  background-position: top;
  background-size: 100% auto;
  background-attachment: fixed;
  box-sizing: border-box;
  height: auto;
}

.sub-content.faq {
  //background-color: rgba(0, 0, 0, 0.7);
  padding-top: 20px;
  display: flex;
}

.sub-menu ul li {
  list-style: none;
  border: 1px solid #000;
  height: 60px;
  line-height: 60px;
  border-right: none;
}

.sub-body ul li {
  list-style: none;
}

.sub-content .sub-menu {
  float: left;
  width: 200px;
  background-color: #fff;
  margin-right: 20px;
  .title {
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    background-color: #e83542;
    text-align: center;
    color: #fff;
  }
  .itms {
    width: 198px;
    height: 45px;
    line-height: 45px;
    position: relative;
    cursor: pointer;
    box-sizing: border-box;
    padding-left: 20px;
    text-decoration: none;
    display: block;
    color: #333;
    border-bottom: 1px solid #ccc;
    &.active {
      margin-top: 0;
      z-index: 9;
      color: #e83542;
      border-left: 3px solid #e83542;
    }
  }
}

.sprite {
  background: url(../img/faq_sprite.png) no-repeat top left;
  width: 33px;
  height: 24px;
  float: left;
  margin-top: 15px;
  margin-left: 30px;
}

.itmstxt {
  font-size: 16px;
  float: left;
  line-height: 57px;
}

.sub-content {
  .sub-menu .itms {
    .icon1 {
      background-position: 0 0;
    }
    &.active .icon1 {
      background-position: 0 -25px;
    }
    .icon2 {
      background-position: 0 -50px;
    }
    &.active .icon2 {
      background-position: 0 -75px;
    }
    .icon3 {
      background-position: 0 -101px;
    }
    &.active .icon3 {
      background-position: 0 -125px;
    }
    .icon4 {
      background-position: 0 -150px;
    }
    &.active .icon4 {
      background-position: 0 -175px;
    }
    .icon5 {
      background-position: 0 -200px;
    }
    &.active .icon5 {
      background-position: 0 -225px;
    }
    .icon6 {
      background-position: 0 -250px;
    }
    &.active .icon6 {
      background-position: 0 -275px;
    }
  }
  .sub-body {
    float: left;
    width: calc(100% - 201px);
    display: none;
    min-height: 412px;
    background-color: #fff;
    padding: 24px;
    box-sizing: border-box;
    .page {
      display: none;
      &.active {
        display: block;
      }
      h3 {
        font-size: 24px;
        font-weight: 600;
        margin-top: 0;
        padding-bottom: 12px;
        margin-bottom: 12px;
        border-bottom: 1px solid #ccc;
      }
      p {
        font-size: 14px;
      }
    }
  }
}

/*Mobile Betting Page Starts Here*/

.subpage-body.mobilebet {
  background: url(../img/mobilebet_bg.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  padding-bottom: 0;
  &.official {
    background: url(../img/mobilebet_bg_official.jpg) no-repeat top center #cc536c;
    .logo-wrapper {
      margin-bottom: 15px;
      span {
        color: #ffee82;
        font-size: 2rem;
      }
    }
  }
}

.sub-content.mobilebet {
  background-color: transparent;
  height: 800px;
  position: relative;
  overflow: auto;
  padding: 40px 0 0 0;
  box-sizing: border-box;
}

.left-col1 {
  background: url(../img/mobile.png) no-repeat;
  width: 534px;
  height: 661px;
  display: inline-block;
  vertical-align: middle;
  float: left;
  margin-top: 80px;
  position: absolute;
  left: -80px;
  img.logo {
    width: 150px;
    height: auto;
    left: 48.5%;
    top: 85px;
    transform: translateX(-50%);
  }
  &.official {
    background: url(../img/mobile_official.png) no-repeat;
  }
}

.right-col1 {
  float: right;
  width: 530px;
  display: inline-block;
  vertical-align: middle;
  height: 620px;
  color: #fff;
  text-align: center;
  box-sizing: border-box;
  margin-top: 60px;
  position: relative;
  &.official {
    .mobile-title {
      background: url(../img/mb_title_official.png) center / contain no-repeat;
    }
    .btn-android,
    .btn-ios {
      background-color: transparent;
    }
    .right-col1-android,
    .right-col1-ios {
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 0;
      padding: 30px 20px;
      box-sizing: border-box;
      height: auto;
      width: auto;
      > .qr-code {
        height: 130px;
        width: 130px;
      }
    }
  }
}

.play-wrapper {
  .logo-wrapper {
    display: inline-block;
    margin: 0 auto;
    img {
      display: inline-block;
      vertical-align: middle;
    }
    .play-icon {
      display: inline-block;
      vertical-align: middle;
      background: url(../img/play.png) center / contain no-repeat;
      width: 75px;
      height: 75px;
    }
  }
  .mobile-title {
    background: url(../img/mb_title.png) center / contain no-repeat;
    width: 100%;
    height: 88px;
    display: inline-block;
  }
}

.right-col1-text2 {
  font-size: 11pt;
  margin: 5px 0;
}

.right-col1-img {
  background: url(../img/mb_advantages.png) no-repeat;
  width: 507px;
  height: 136px;
  margin: 30px 20px;
}

.right-col1-text3 {
  font-size: 12.5pt;
  padding-bottom: 25px;
  position: relative;
  label {
    &:before {
      content: "";
      position: absolute;
      display: block;
      width: 82px;
      border-top: 1px solid #fff;
      top: 12px;
      left: 13%;
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 82px;
      border-top: 1px solid #fff;
      top: 12px;
      left: 64%;
    }
  }
}

.qrcode-wrapper {
  margin: 20px 0;
}

.right-col1-wrapper {
  display: inline-block;
  margin: 0 10px;
  width: 180px;
}

.right-col1-android {
  display: inline-block;
  width: 192px;
  height: 192px;
  background-color: #fff;
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.tx-android,
.tx-ios {
  color: #fff;
  display: block;
  font-size: 11pt;
  margin: 10px;
}

.btn-android {
  background-color: #fff;
  width: 180px;
  height: 55px;
  border-radius: 55px;
  left: 0;
  right: 0;
  margin: 20px auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-ios {
  background-color: #fff;
  width: 180px;
  height: 55px;
  border-radius: 55px;
  left: 0;
  right: 0;
  margin: 20px auto;
  position: relative;
}

.btn-qr {
  width: 180px;
  height: 56px;
  left: 0;
  top: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-col1-ios {
  display: inline-block;
  width: 192px;
  height: 192px;
  background-color: #fff;
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.left-col2 {
  float: left;
  width: 55%;
  height: 560px;
  position: absolute;
  top: 85px;
}

.left-col2-box {
  text-align: center;
  width: 160px;
  margin: 0 0 50px 100px;
  display: inline-block;
  vertical-align: top;
}

.left-col2 .left-col2-box {
  &:first-child .left-col2-icon {
    background: url(../img/mb_security.png) 0 0 no-repeat;
    width: 160px;
    height: 160px;
  }
  &:nth-child(2) .left-col2-icon {
    background: url(../img/mb_security.png) -165px 0 no-repeat;
    width: 160px;
    height: 160px;
  }
  &:nth-child(3) .left-col2-icon {
    background: url(../img/mb_security.png) 0 -160px no-repeat;
    width: 160px;
    height: 160px;
  }
  &:last-child .left-col2-icon {
    background: url(../img/mb_security.png) -165px -160px no-repeat;
    width: 160px;
    height: 160px;
  }
}

.left-col2-text1 {
  font-size: 13pt;
  font-weight: 600;
  margin: 12px 0 5px 0;
}

.left-col2-text2 {
  font-size: 10pt;
  color: #646464;
}

.right-col2 {
  background: url(../img/mb_phone2.png) no-repeat;
  width: 558px;
  height: 580px;
  position: absolute;
  right: -200px;
  top: 85px;
}

$navigator: "../img/icon-daohang.svg";
$app: "../img/icon-app.svg";
$wechat: "../img/wechat.png";
$qq: "../img/qq.png";
$customerService: "../img/customer-service.png";
$customerServiceApp: "../img/icon-kefuapp.svg";
$livechatService: "../img/icon-livechat.svg";
$macOS: "../img/macos.png";
$windows: "../img/windows.png";
$backToTop: "../img/up-arrow.svg";

.left_adv {
  height: 460px;
  width: 129px;
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
  a {
    display: block;
  }
  img {
    width: 100%;
  }

  &.official {
    .appqr-wrapper {
      .appqrcode {
        background-color: #a644de;
      }
    }
    .text {
      color: #ffff00;
    }
    .appdlurl {
      color: #000000;
    }
  }
  &.non-official {
    .appqr-wrapper {
      .appqrcode {
        background-color: #a644de;
      }
    }
    .text {
      color: #ffff00;
    }
    .appdlurl {
      color: #000000;
    }
  }
}

.appqr-wrapper {
  z-index: 99999;
  width: 120px;
  margin: auto;
  .qr-code {
  }
  .appqrcode {
    width: 118px;
    background-color: #ffd265;
    border-radius: 10px;
    padding: 6px;
    box-sizing: border-box;
    img {
      width: 98px;
      height: 98px;
      background-color: #fff;
      box-sizing: border-box;
      padding: 6px;
    }
  }
  .text {
    color: #333333;
    text-align: center;
    font-size: 16px;
    line-height: 21px;
    font-style: italic;
    margin-top: 7px;
    font-weight: 600;
  }
}

.appdlurl {
  text-align: center;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;
  margin-top: 2px;
  letter-spacing: -0.5px;
}

.sidebar {
  position: fixed;
  top: 50%;
  right: 1rem;
  width: 50px;
  z-index: 50;
  transform: translateY(-50%);
  .sidebar-item {
    display: block;
    font-size: 50px;
    border-radius: 50%;
    width: 1em;
    min-width: 1em;
    height: 1em;
    min-height: 1em;
    background-size: 100% auto;
    background-position-x: center;
    background-repeat: no-repeat;
    position: relative;
    transition: all 200ms ease-in-out;
    box-sizing: border-box;
    &:not(:last-child) {
      margin-bottom: 5px;
    }
    &:hover {
      .sidebar-desc {
        transform: translate(0, -50%);
        opacity: 1;
        visibility: visible;
        &.appqr-wrapper {
          transform: translate(0);
        }
      }
      @supports (filter: brightness(1.2)) {
        &:hover {
          filter: brightness(1.2);
        }
      }
    }
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 65%;
      height: 65%;
      margin: auto;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &.morse {
      background-image: url(../img/morse-icon.png);
    }
    &.navigator {
      background-image: linear-gradient(to bottom, #c9fa13, #64d300);
      &:before {
        background-image: url($navigator);
      }
    }
    &.app {
      background-image: linear-gradient(to bottom, #fa4a4f, #d30340);
      &:before {
        background-image: url($app);
      }
      .appqr-wrapper {
        width: 130px;
        height: 195px;
        padding: 10px 0;
        box-sizing: border-box;
        .qr-code {
          margin: 0 0 4px;
        }
        .text {
          color: #ffbf2f;
          text-align: center;
          font-size: 18px;
          line-height: 21px;
          font-weight: bold;
          font-style: italic;
        }
        .appdlurl {
          color: #fff;
          text-align: center;
          font-size: 18px;
          font-style: italic;
          font-weight: 600;
          letter-spacing: -0.5px;
        }
      }
    }
    &.qq {
      background-image: linear-gradient(to bottom, #0090db, #0075b1);
      &:before {
        background-image: url($qq);
      }
    }
    &.wechat {
      background-image: linear-gradient(to bottom, #00bd24, #00991b);
      &:before {
        background-image: url($wechat);
      }
    }
    &.customer-service {
      background-image: linear-gradient(to bottom, #8530f5, #6d24ce);
      // background-image: linear-gradient(to bottom, #fa8f4c, #ff5b4c);
      &:before {
        background-image: url($customerService);
      }
    }
    &.customer-service-app {
      background-image: linear-gradient(to bottom, #ae511c, #843d15);
      &:before {
        background-image: url($customerServiceApp);
      }
    }
    &.livechat-service {
      background-image: linear-gradient(to bottom, #fad000, #d35300);
      &:before {
        background-image: url($livechatService);
      }
    }
    &.macos-download {
      background-image: linear-gradient(to bottom, #fa1499, #d31181);
      &:before {
        background-image: url($macOS);
      }
    }
    &.windows-download {
      background-image: linear-gradient(to bottom, #8530f5, #6d24ce);
      &:before {
        background-image: url($windows);
      }
    }

    &.back-to-top {
      background: #0067ac;
      opacity: 0;
      &:before {
        background-image: url($backToTop);
        background-size: 50% 50%;
      }
    }

    .sidebar-desc {
      display: block;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      text-decoration: none;
      position: absolute;
      top: 50%;
      transform: translate(-30%, -50%);
      right: 60px;
      background-color: rgba(0, 0, 0, 0.7);
      border-radius: 6px;
      padding: 5px;
      text-align: center;
      box-sizing: border-box;
      font-size: 0.8125rem;
      color: #fff;
      transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
      &:not(.appqr-wrapper) {
        white-space: nowrap;
      }
      @supports (-webkit-backdrop-filter: blur(10px)) {
        -webkit-backdrop-filter: blur(10px);
      }
      canvas {
        width: 200px;
        height: 200px;
      }
      &:before {
        content: "";
        position: absolute;
        top: 50%;
        right: -10px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-left: 5px solid rgba(0, 0, 0, 0.7);
      }
      &.appqr-wrapper {
        top: 0;
        transform: translate(-50%, 0);
        &:before {
          content: "";
          position: absolute;
          top: 10%;
          right: -10px;
          transform: translateY(-50%);
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-right: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 5px solid rgba(0, 0, 0, 0.7);
        }
        .appqrcode {
          width: 150px;
          background-color: #fff;
          border-radius: 4px 4px 0 0;
          padding: 10px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          img {
            display: none;
            width: 130px;
            height: 130px;
          }
          canvas {
            width: 130px !important;
            height: 130px !important;
            background-color: #fff;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
          }
          .text {
            color: #000;
            text-align: center;
            font-size: 16px;
            line-height: 19px;
            font-style: italic;
            margin-top: 7px;
          }
        }
        .appdlurl {
          text-align: center;
          font-size: 16px;
          font-style: italic;
          font-weight: 600;
          color: #fff;
          border-radius: 0 0 4px 4px;
          padding: 5px 3px;
        }
      }
    }
  }
}

.left-floater {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 90;
  .nav-wrapper {
    background-image: url(../img/left-floater-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: 140px;
    height: 90px;
  }
  .appqr-wrapper {
    position: relative;
    background-image: url(../img/left-floater-bg.png);
    background-size: cover;
    background-position: center bottom;
    background-repeat: no-repeat;
    width: 140px;
    height: 170px;
  }
  .url-nav {
    position: absolute;
    top: 45px;
    left: 10px;
    background-color: #dd5050;
    height: 25px;
    width: 120px;
    border-radius: 25px;
    border: 2px solid #f3a84b;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 700;
  }
  .download-qr {
    position: absolute;
    top: 15px;
    left: 16px;
    width: 109px;
    text-align: center;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    h3 {
      margin: 0;
      font-size: 16px;
    }
    .qr-code {
      margin-top: 8px;
      canvas {
        width: 100px !important;
        height: 100px !important;
      }
    }
    .url-app {
      margin-top: 12px;
      color: white;
      width: 110px;
      word-wrap: break-word;
      text-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  display: none;
  z-index: 999;
  &:target {
    display: block;
  }
}

.popup {
  margin: 80px auto;
  padding: 20px;
  background: #000;
  width: 70%;
  height: calc(100% - 200px);
  position: relative;
  transition: all 5s ease-in-out;
  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    z-index: 1;
    &:hover {
      color: #ce3333;
    }
  }
  .content {
    text-align: center;
  }
}

.content .video-js {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}

.popup img {
  max-width: 100%;
}

.video-js .vjs-big-play-button {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  margin: auto;
}

/*Jssor Slider*/

/* jssor slider loading skin spin css */

.jssorb032 {
  position: absolute;
  .i {
    position: absolute;
    cursor: pointer;
    .b {
      fill: #fff558;
    }
    &:hover .b {
      fill: #f45b25;
      fill-opacity: 0.6;
    }
  }
  .iav .b {
    fill: #f45b25;
    fill-opacity: 1;
  }
  .i.idn {
    opacity: 0.3;
  }
}

.jssora051 {
  display: block;
  position: absolute;
  cursor: pointer;
  .a {
    fill: none;
    stroke: #fff;
    stroke-width: 360;
    stroke-miterlimit: 10;
  }
  &:hover {
    opacity: 0.8;
  }
  &.jssora051dn {
    opacity: 0.5;
  }
  &.jssora051ds {
    opacity: 0.3;
    pointer-events: none;
  }
}

.page-faq {
  p,
  li {
    font-size: 14px;
    line-height: 20px;
  }
}

.landing-advertise {
  .ad-frame {
    background-color: transparent !important;
  }

  .btn-close-bg {
    display: none;
  }

  .btn-close {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
